<template>
	<div class="EnterpriseAdd">
		<div class="top-title">
			<van-icon name="arrow-left" class="icon-fan" @click="fan()" />
			<span>{{title_span}}</span>
		</div>

		<div class="alls">
			<van-tabs v-model:active="active" class="tabBox" color="#025193" @click="tabClick">
				<van-tab name="1" class="tabItem">
					<template #title>
						<img src="../../img/title_img.png" style="width: 0.533rem; margin-right: 0.133rem ;" />
						<div>从业人员信息</div>
					</template>

					<div class="plusBox">
						<van-button icon="plus" type="primary" @click="personnelAdd()">新增人员</van-button>
					</div>

					<van-pull-refresh v-model="isDownLoading" @refresh="onRefresh" :head-height="200" class="pt0px">
						<div v-for="(item,index) in dtWinNumberInfos" :key="index" class="list-user">
							<div class="content content-flex">
								<div class="content-f">
									<div class="content-t">姓名</div>
									<div class="content-c">{{isNull(item.userName)}}</div>
								</div>
								<div class="content-f">
									<div class="content-t">性别</div>
									<div class="content-c">
										<span v-if="item.userSex==0">女</span>
										<span v-else-if="item.userSex==1">男</span>
										<span v-else>未知</span>
									</div>
								</div>
							</div>

							<div class="content">
								<div class="content-t">身份证号码</div>
								<div class="content-c">{{isNull(item.userNumber)}}</div>
							</div>

							<div class="content content-flex">
								<div class="content-f">
									<div class="content-t">
										<span v-if="item.userCardType==0">健康证编号</span>
										<span v-if="item.userCardType==1">药师资格证编号</span>
										<span v-if="item.userCardType==2">特种设备操作证编号</span>
									</div>
									<div class="content-c">{{isNull(item.userCardNumber)}}</div>
								</div>
								<div class="content-f">
									<div class="content-t">有效日期</div>
									<div class="content-c">{{isNull(item.userCardEtime)}}</div>
								</div>
							</div>

							<div class="content content-flex" v-if="item.userCardType==1">
								<div class="content-f">
									<div class="content-f">
										<div class="content-t">注册证编号</div>
										<div class="content-c">{{isNull(item.userRegisterNumber)}}</div>
									</div>
								</div>
								<div class="content-f">
									<div class="content-t">注册日期</div>
									<div class="content-c">{{isNull(item.userRegisterTime)}}</div>
								</div>
							</div>

							<div class="content">
								<div class="content-t">
									<span v-if="item.userCardType==0">健康证照片</span>
									<span v-if="item.userCardType==1">药师资格证照片</span>
									<span v-if="item.userCardType==2">特种设备操作证照片</span>
								</div>
								<div class="content-c">
									<span v-if="!item.userCardSrc">暂无</span>
									<img v-else @click="showPopup(`${baseUrlImg}${item.userCardSrc}`)" :src="`${baseUrlImg}${item.userCardSrc}`" />
								</div>
							</div>

							<div class="content content-flex" v-if="item.userCardType==1">
								<div class="content-f">
									<div class="content-t">执业地区</div>
									<div class="content-c">
										<div>{{isNull(item.userCardAddress)}}</div>
									</div>
								</div>
								<div class="content-f">
									<div class="content-t">执业范围</div>
									<div class="content-c">
										<span>{{isNull(item.userCardRange)}}</span>
									</div>
								</div>
							</div>

							<div class="content content-flex" v-if="item.userCardType==1">
								<div class="content-f">
									<div class="content-t">执业类型</div>
									<div class="content-c">
										<div>{{isNull(item.pharmacistName)}}</div>
									</div>
								</div>
								<div class="content-f">
									<div class="content-t">执业单位</div>
									<div class="content-c">
										<span>{{isNull(item.enterpriseName)}}</span>
									</div>
								</div>
							</div>

							<div class="edit-or-del-box">
								<van-button type="primary" :to="{ path: '/personnelEidt/'+item.userId}">修改</van-button>
								<van-button type="warning" @click="deleteUser(item.userId)">删除</van-button>
							</div>
						</div>

						<van-list v-model:loading="isUpLoading" :finished="upFinished" :offset="150" :finished-text="nullTip" @load="onLoad" />
					</van-pull-refresh>

					<van-image-preview :closeable="true" :show-index="false" v-model:show="show" :images="urlImg"></van-image-preview>
				</van-tab>

				<van-tab name="2" class="tabItem">
					<template #title>
						<img src="../../img/title_img.png" style="width: 0.533rem; margin-right: 0.133rem " />
						<div>特种设备信息</div>
					</template>

					<div class="plusBox equipment-add">
						<van-button icon="plus" type="primary" to="/EquipmentAdd/">新增设备</van-button>
					</div>

					<van-pull-refresh v-model="isDownLoading" @refresh="onRefresh" :head-height="200" class="pt0px">
						<div v-for="(item,index) in dtWinNumberInfosEq" :key="index" class="list">
							<div class="list-top">
								<img src="../../img/e-top.png" />
								<div class="lt-number">{{index+1}}</div>
								<div class="lt-title">{{item.facilityName}}</div>
							</div>
							<div class="list-content">
								<div class="lc-div">
									<span>设备型号：</span>
									{{isNull(item.facilityModel)}}
								</div>
								<div class="lc-div">
									<span>设备状态：</span>
									{{isNull(item.facilityStateEn)}}
								</div>
								<div class="lc-div">
									<span>设备类型：</span>
									{{isNull(item.facilityTypeName)}}
								</div>
							</div>
							<div class="edit-or-del-box1">
								<van-button type="primary" :to="{ path: '/equipmentEdit/'+item.facilityId}">修改</van-button>
								<van-button type="warning" @click="deleteFacility(item.facilityId)">删除</van-button>
							</div>
						</div>
						<van-list v-model:loading="isUpLoading" :finished="upFinished" :offset="150" :finished-text="nullTip" @load="onLoad" />
					</van-pull-refresh>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>
<style scoped>
	.top-title {
		background-color: #025193;
		color: white;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
		z-index: 999;
		text-align: center;
		font-size: 0.49rem;
		letter-spacing: 0.026667rem;
	}

	.icon-fan {
		position: absolute;
		left: 0.133333rem;
		top: 0.24rem;
		font-size: 0.746667rem;
	}

	.alls {
		padding-top: 1.173333rem;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		background-color: #eae6e5;
	}

	::v-deep .tabBox .van-tabs__line {
		width: 3.6rem;
	}

	::v-deep .tabBox .van-tabs__wrap,
	.van-tabs__wrap scroll-view,
	.van-tabs__nav,
	.van-tab,
	van-tab__text {
		height: 1.173333rem !important;
	}

	::v-deep .tabBox .van-tab__text {
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 0.467rem;
	}

	::v-deep .van-tab {
		background-color: #eae6e5 !important;
	}

	.pt0px {
		padding-top: 0rem;
		background-color: #eae6e5;
	}

	.list-user {
		background-color: #fff;
		width: 100%;
		margin-bottom: 0.4rem;
	}

	.content {
		width: 94%;
		margin-left: 3%;
		border-bottom: 1px #eeeeee solid;
	}

	.content-t {
		color: #2a8df0;
		font-size: 0.373333rem;
		padding-top: 0.186667rem;
		width: 100%;
		text-indent: 0.133333rem;
	}

	.content-c {
		color: #464646;
		font-size: 0.373333rem;
		padding-bottom: 0.186667rem;
		margin-top: 0.053333rem;
		text-indent: 0.133333rem;
		width: 100%;
	}

	.content-c1 {
		overflow-x: scroll;
		white-space: nowrap;
	}

	.content-flex {
		display: flex;
	}

	.content-f {
		flex: 1;
	}

	.content-c img {
		height: 1.973333rem;
		padding: 0.133333rem 0.133333rem 0rem 0.133333rem;
	}

	::v-deep .van-popup__close-icon {
		color: #2a8df0;
	}

	.plusBox {
		height: 1.477rem;
		border-bottom: 1px #eeeeee solid;
		background-color: #fff;
	}

	.equipment-add {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 0.4rem;
	}

	::v-deep .plusBox .van-button--primary {
		color: #fff;
		background-color: orange;
		border: 1px solid orange;
		margin-top: 0.267rem;
		width: 3.527rem;
		height: 0.933rem;
		margin-left: 0.733rem;
		font-size: 0.48rem;
		font-weight: 700;
	}

	::v-deep .equipment-add .van-button--primary {
		margin-right: 0.733rem !important;
	}

	.edit-or-del-box {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
		padding-top: 0.267rem;
		padding-bottom: 0.267rem;
		display: flex;
		justify-content: space-between;
	}

	.edit-or-del-box button {
		width: 45%;
		height: 1.067rem;
		font-size: 0.467rem;
	}

	.edit-or-del-box1 {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
		padding-top: 0.267rem;
		padding-bottom: 0.267rem;
		display: flex;
		justify-content: space-between;
	}

	.edit-or-del-box1 button {
		width: 45%;
		height: 1.067rem;
		font-size: 0.32rem;
	}

	.list {
		width: 96%;
		margin-bottom: 0.4rem;
		margin-left: 2%;
		background-color: #fff;
		position: relative;
	}

	.list-top {
		height: 1.2rem;
		line-height: 1.2rem;
		position: relative;
		border-bottom: 1px #025193 solid;
		width: 100%;
	}

	.list-top img {
		width: 50%;
		position: absolute;
		top: 0px;
		left: 25%;
	}

	.list-top .lt-number {
		background-color: #025193;
		color: white;
		position: absolute;
		width: 0.8rem;
		height: 0.8rem;
		line-height: 0.8rem;
		top: 0.133333rem;
		left: 3%;
		text-align: center;
		font-size: 0.373333rem;
	}

	.list-top .lt-title {
		display: inline-block;
		margin-left: 1.6rem;
		font-size: 0.373333rem;
	}

	.list-content {
		width: 94%;
		margin-left: 3%;
		padding-top: 0.133333rem;
		padding-bottom: 0.133333rem;
	}

	.list-content .lc-div {
		width: 100%;
		font-size: 0.373333rem;
		height: 0.666667rem;
		line-height: 0.666667rem;
		color: #459bf5;
	}

	.list-content .lc-div span {
		font-size: 0.373333rem;
		color: #000;
	}

	.list-bottom {
		width: 94%;
		margin-left: 3%;
		position: relative;
		padding-bottom: 0.2rem;
		height: 0.933333rem;
	}

	.list-bottom .lb-button {
		height: 0.933333rem;
		line-height: 0.933333rem;
		width: 2.666667rem;
		text-align: center;
		color: #fff;
		background-color: #025193;
		font-size: 0.373333rem;
		position: absolute;
		right: 0px;
		border-radius: 0.133333rem;
	}
</style>
<script>
	// 从业人员信息列表，特种设备信息页面列表
	import EnterpriseAdd from "@/js/add/EnterpriseAdd";

	export default {
		...EnterpriseAdd,
	};
</script>
