import { getCurrentInstance, toRefs, inject, ref, reactive } from "vue";
import { useRouter } from 'vue-router'
import { baseUrlImg } from '@/utils/common'

export default {
	setup(props, context) {
		
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();
		
		const dataE = inject('dataAll')
		
		const dataAll = reactive({
			title_span: ref(null),
			dataType: proxy.$router.currentRoute.value.params.id,
			active: ref("1"),
			userData: {},
			baseUrlImg: baseUrlImg(),
			show: ref(false),
			nullTip: "",
			pageSize: 10, 			// 每页条数
			pageIndex: 0, 			// 页码
			dtWinNumberInfos: [], 	// 请求数据
			dtWinNumberInfosEq: [],
			isUpLoading: false, 	// 上拉加载
			upFinished: false, 		// 上拉加载完毕
			isDownLoading: false, 	// 下拉刷新
			offset: 100, 			// 滚动条与底部距离小于 offset 时触发load事件;
			urlImg: []
		})

		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};

		const fan = () => {
			router.push({
				path: "/enterpriseEditJump/" + sessionStorage.getItem("enterpriseQrNumber"),
				query: { from: 'add' }
			});
		}

		const onLoad = () => {
			let type = parseInt(dataAll.dataType);
			dataAll.active = type.toString();
			if (type === 1) {
				document.title = "从业人员信息编辑";
				dataAll.title_span = "从业人员信息编辑";
				if (dataAll.isDownLoading) {
					dataAll.dtWinNumberInfos = [];
					dataAll.isDownLoading = false;
					dataAll.pageIndex = 1;
				} else {
					dataAll.pageIndex++;
				}
				queryUserByPage()
			} else {
				document.title = "特种设备信息编辑";
				dataAll.title_span = "特种设备信息编辑";
				if (dataAll.isDownLoading) {
					dataAll.dtWinNumberInfosEq = [];
					dataAll.isDownLoading = false;
					dataAll.pageIndex = 1;
				} else {
					dataAll.pageIndex++;
				}
				queryFacilityByPage()
			}
		};

		let queryUserByPage = async() =>{
			await proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/userPage`),
				method: 'post',
				data: proxy.$http.adornData({
					'enterpriseQrNumber': sessionStorage.getItem("enterpriseQrNumber"),
					'page': dataAll.pageIndex,
					'limit': dataAll.pageSize
				})
			}).then( ({ data }) => {
				if (data && data.code === 0) {
					dataAll.dtWinNumberInfos.push(...data.data.list);
					if (dataAll.dtWinNumberInfos.length == 0 && dataAll.pageIndex == 1) {
						dataAll.nullTip = "暂无从业人员信息";
					}
					if (data.data.currPage >= data.data.totalPage) {
						dataAll.upFinished = true;
					} else {
						dataAll.isUpLoading = false;
					}
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({ path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQrNumber") });
				} else {
					proxy.$toast(data.msg);
					dataAll.isUpLoading = true;
				}
			})
		} 

		let queryFacilityByPage = async () => {
			await proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/facilityPage`),
				method: 'post',
				data: proxy.$http.adornData({
					'enterpriseQrNumber': sessionStorage.getItem("enterpriseQrNumber"),
					'page': dataAll.pageIndex,
					'limit': dataAll.pageSize
				})
			}).then( ({ data }) => {
				if (data && data.code === 0) {
					dataAll.dtWinNumberInfosEq.push(...data.page.list);
					if (dataAll.dtWinNumberInfosEq.length == 0 && dataAll.pageIndex == 1) {
						dataAll.nullTip = "暂无特种设备信息";
					}
					if (data.page.currPage >= data.page.totalPage) {
						dataAll.upFinished = true;
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({ path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQrNumber") });
					} else {
						dataAll.isUpLoading = false;
					}
				} else {
					proxy.$toast(data.msg);
					dataAll.isUpLoading = true;
				}
			})
		}

		const onRefresh = (type) => {
			// 清空列表数据
			dataAll.upFinished = false;
			// 重新加载数据
			// 将 isUpLoading 设置为 true，表示处于加载状态
			dataAll.isUpLoading = true;
			onLoad(type);
		};

		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}

		const personnelAdd = () => {
			router.push("/personnelAdd/");
		}
		
		const tabClick = (name) => {
			dataAll.dataType = name;
			dataAll.pageSize = 10 // 每页条数
			dataAll.pageIndex = 0 // 页码
			dataAll.isUpLoading = false // 上拉加载
			dataAll.upFinished = false // 上拉加载完毕
			dataAll.isDownLoading = false // 下拉刷新
			if (name === '1') {
				dataAll.dtWinNumberInfos = [], // 请求数据
				onLoad();
			} else {
				dataAll.dtWinNumberInfosEq = [],
				onLoad();
			}
		}

		const deleteUser = (userId) => {
			proxy.$dialog.confirm({
				message: '确认删除当前用户？',
			}).then(() => {
				proxy.$http({
					url: proxy.$http.adornUrl(`/app/qr/deleteUser`),
					method: 'post',
					data: proxy.$http.adornData({
						'userId': userId
					}),
					headers: {
						token: sessionStorage.getItem("userToken")
					}
				}).then(({
					data
				}) => {
					if (data && data.code === 0) {
						dataAll.dtWinNumberInfos = [];
						dataAll.isDownLoading = false;
						dataAll.pageIndex = 1;
						dataAll.upFinished = true
						proxy.$http({
							url: proxy.$http.adornUrl(`/app/qr/userPage`),
							method: 'post',
							data: proxy.$http.adornData({
								'enterpriseQrNumber': sessionStorage.getItem("enterpriseQrNumber"),
								'page': dataAll.pageIndex,
								'limit': dataAll.pageSize
							})
						}).then(({
							data
						}) => {
							if (data && data.code === 0) {
								dataAll.dtWinNumberInfos.push(...data.data.list);
								if (data.data.currPage >= data.data.totalPage) {
									dataAll.upFinished = true;
								} else {
									dataAll.isUpLoading = false;
								}
							} else if (data.code === 401) {
								sessionStorage.setItem("userToken", "");
								router.push({
									path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQrNumber")
								});
							} else {
								proxy.$toast(data.msg);
								dataAll.isUpLoading = true;
							}
						})
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + sessionStorage.getItem(
								"enterpriseQrNumber")
						});
					} else {
						proxy.$toast(data.msg);
					}
				})
			}).catch(() => {});
		}

		const deleteFacility = (facilityId) => {
			proxy.$dialog.confirm({
				message: '确认删除当前设备？',
			}).then(() => {
				proxy.$http({
					url: proxy.$http.adornUrl(`/app/qr/deleteFacility`),
					method: 'post',
					data: proxy.$http.adornData({
						'facilityId': facilityId
					}),
					headers: {
						token: sessionStorage.getItem("userToken")
					}
				}).then(({
					data
				}) => {
					if (data && data.code === 0) {
						dataAll.dtWinNumberInfosEq = [];
						dataAll.isDownLoading = false;
						dataAll.pageIndex = 1;
						dataAll.upFinished = true;
						proxy.$http({
							url: proxy.$http.adornUrl(`/app/qr/facilityPage`),
							method: 'post',
							data: proxy.$http.adornData({
								'enterpriseQrNumber': sessionStorage.getItem("enterpriseQrNumber"),
								'page': dataAll.pageIndex,
								'limit': dataAll.pageSize
							})
						}).then(({
							data
						}) => {
							if (data && data.code === 0) {
								dataAll.dtWinNumberInfosEq.push(...data.page.list);
								if (data.page.currPage >= data.page.totalPage) {
									dataAll.upFinished = true;
								} else if (data.code === 401) {
									sessionStorage.setItem("userToken", "");
									router.push({
										path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQrNumber")
									});
								} else {
									dataAll.isUpLoading = false;
								}
							} else {
								proxy.$toast(data.msg);
								dataAll.isUpLoading = true;
							}
						})
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + sessionStorage.getItem(
								"enterpriseQrNumber")
						});
					} else {
						proxy.$toast(data.msg);
					}
				})
			}).catch(() => {});
		}

		return {
			...toRefs(dataAll),
			fan,
			onLoad,
			onRefresh,
			showPopup,
			isNull,
			personnelAdd,
			tabClick,
			deleteUser,
			deleteFacility
		}
	}
}
